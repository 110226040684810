import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import android from "../../images/iz-calculator/android.svg"
import ios from "../../images/iz-calculator/ios.svg"
import web from "../../images/iz-calculator/web.svg"
import desk from "../../images/iz-calculator/desk.svg"
import hybrid from "../../images/iz-calculator/Hybrid.svg"
import UnlockCost from "./UnlockCost"
import * as styles from "./Form1.module.scss"
import Stepper from "./Stepper"

const cardsData = [
  {
    image: android,
    description: `Android`,
  },
  {
    image: ios,
    description: `iOS`,
  },
  {
    image: hybrid,
    description: `Hybrid`,
  },
  {
    image: web,
    description: `Web`,
  },
  {
    image: desk,
    description: `Desktop`,
  },
]

const Form1 = ({ setFormValues, preValues, handleNext, handleBack, step }) => {
  const initValues = {
    platform: preValues?.platform || "",
  }

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const forSubmit = () => {
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    console.log(values)
    setFormValues({ ...preValues, ...values })
    handleNext()
  }

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "platform":
          if (!value) newErrors[key] = "This field is required!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  const less_cards = cardsData.length % 3
  const last_cards = less_cards !== 0 ? cardsData.slice(-less_cards) : null
  // console.log(less_cards, last_cards)

  return (
    <div className={styles.form1}>
      <Container>
        <div className={styles.formData}>
          <Row>
            <Col lg={8}>
              <div className={`${styles.mainHeading}`}>
                <h1>Software Development Cost Calculator</h1>
                <p className={styles.description}>
                  No software development cost estimation calculator can compete
                  with the InvoZone tool. Answer the following questions and get
                  the first draft of the cost estimation.
                </p>
              </div>
              <span className={styles.bar2}></span>

              <Stepper step={step} />
              <div className={styles.help}>
                <p className={styles.cost}>How can we help you?</p>
                <Row className="gap-30">
                  {cardsData?.map((item, index) => (
                    <Col
                      lg={
                        last_cards?.includes(item)
                          ? less_cards === 1
                            ? 12
                            : 6
                          : 4
                      }
                    >
                      <div className={styles.staffCheck}>
                        <label>
                          <input
                            type="radio"
                            name={"platform"}
                            value={item?.description}
                            checked={values?.platform === item?.description}
                            onChange={handleChange}
                          />
                          {item?.label}
                          <span className={styles.checkmark} />
                          <div
                            className={`${styles.planContent} ${
                              values?.platform === item?.description &&
                              styles.active
                            }`}
                          >
                            <img loading="lazy" src={item?.image} alt="" />

                            <p>{item?.description}</p>
                          </div>
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>
                {errors.platform && (
                  <small type="invalid" className={"w-100 text-danger"}>
                    {errors.platform}
                  </small>
                )}
                <span className={styles.bar}></span>
                <div className={styles.buttons}>
                  <hr />
                  <div className={styles.btn}>
                    <button
                      className={`${styles.next} mt-2`}
                      onClick={forSubmit}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <UnlockCost values={preValues} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Form1
