// extracted by mini-css-extract-plugin
export var back = "Form2-module--back--4212e";
export var bar = "Form2-module--bar--82875";
export var btn = "Form2-module--btn--df672";
export var buttons = "Form2-module--buttons--08921";
export var checkmark = "Form2-module--checkmark--0c309";
export var cost = "Form2-module--cost--7f372";
export var form1 = "Form2-module--form1--8ef1b";
export var formData = "Form2-module--formData--89a5c";
export var help = "Form2-module--help--9e220";
export var next = "Form2-module--next--a43bd";
export var planContent = "Form2-module--planContent--86203";
export var planDetails = "Form2-module--planDetails--bdf49";
export var questionAns = "Form2-module--questionAns--90fe8";
export var questionMark = "Form2-module--questionMark--c5eeb";
export var skip = "Form2-module--skip--0997d";
export var staffCheck = "Form2-module--staffCheck--d383e";