// extracted by mini-css-extract-plugin
export var back = "Form6-module--back--f5c56";
export var bar = "Form6-module--bar--dc48d";
export var btn = "Form6-module--btn--c077d";
export var buttons = "Form6-module--buttons--68845";
export var checkmark = "Form6-module--checkmark--94cee";
export var cost = "Form6-module--cost--30f65";
export var form1 = "Form6-module--form1--9e885";
export var formData = "Form6-module--formData--0950f";
export var help = "Form6-module--help--f11de";
export var next = "Form6-module--next--cd4b4";
export var planContent = "Form6-module--planContent--8749f";
export var planDetails = "Form6-module--planDetails--e8edf";
export var questionAns = "Form6-module--questionAns--bc846";
export var questionMark = "Form6-module--questionMark--42ab6";
export var skip = "Form6-module--skip--23914";
export var staffCheck = "Form6-module--staffCheck--498cd";