// extracted by mini-css-extract-plugin
export var back = "Form3-module--back--55710";
export var bar = "Form3-module--bar--a3ee8";
export var btn = "Form3-module--btn--dd48a";
export var buttons = "Form3-module--buttons--697f3";
export var cost = "Form3-module--cost--33632";
export var form1 = "Form3-module--form1--1cad5";
export var formData = "Form3-module--formData--e4abb";
export var help = "Form3-module--help--02704";
export var next = "Form3-module--next--dabce";
export var skip = "Form3-module--skip--dde0c";