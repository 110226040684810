import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
import android from "../../images/iz-calculator/Frame.svg"
import ios from "../../images/iz-calculator/Frame1.svg"
import web from "../../images/iz-calculator/Frame2.svg"
import desk from "../../images/iz-calculator/Frame3.svg"
import education from "../../images/iz-calculator/education.svg"
import demand from "../../images/iz-calculator/On-demand.svg"
import Tourism from "../../images/iz-calculator/Tourism.svg"
import Automobile from "../../images/iz-calculator/Automobile.svg"
import RealEstate from "../../images/iz-calculator/RealEstate.svg"
import Insurance from "../../images/iz-calculator/Insurance.svg"
import Others from "../../images/iz-calculator/Others.svg"
import UnlockCost from "./UnlockCost"
import * as styles from "./Form2.module.scss"
import Stepper from "./Stepper"

const cardsData = [
  {
    image: android,
    description: `Healthcare`,
  },
  {
    image: ios,
    description: `Fintech`,
  },
  {
    image: web,
    description: `eCommerce`,
  },
  {
    image: desk,
    description: `Food & Grocery`,
  },
  {
    image: education,
    description: `Education`,
  },
  {
    image: demand,
    description: `On-demand`,
  },
  {
    image: Tourism,
    description: `Tourism & Travel`,
  },
  {
    image: Automobile,
    description: `Automobile`,
  },
  {
    image: RealEstate,
    description: `Real Estate`,
  },
  {
    image: Insurance,
    description: `Insurance`,
  },
  {
    image: Others,
    description: `Others`,
  },
]

const Form2 = ({ setFormValues, preValues, handleNext, handleBack, step }) => {
  const initValues = {
    project_domain: preValues?.project_domain || "",
  }

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const forSubmit = () => {
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    console.log(values)
    setFormValues({ ...preValues, ...values })
    handleNext()
  }

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "project_domain":
          if (!value) newErrors[key] = "This field is required!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  return (
    <div className={styles.form1}>
      <Container>
        <div className={styles.formData}>
          <Row>
            <Col lg={8}>
              <Stepper step={step} />
              <div className={styles.help}>
                <p className={styles.cost}>Which domain best describes your project?</p>
                <Row className="">
                  {cardsData?.map((item, index) => (
                    <Col lg={3} key={index}>
                      <div className={styles.staffCheck}>
                        <label>
                          <input
                            type="radio"
                            name={"project_domain"}
                            value={item?.description}
                            checked={
                              values?.project_domain === item?.description
                            }
                            onChange={handleChange}
                          />
                          {item?.label}
                          <span className={styles.checkmark} />
                          <div className={styles.planContent}>
                            <img loading="lazy" src={item?.image} alt="" />

                            <p className={styles.planDetails}>
                              {item?.description}
                            </p>
                          </div>
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>
                {errors.project_domain && (
                  <small type="invalid" className={"w-100 text-danger"}>
                    {errors.project_domain}
                  </small>
                )}
                <span className={styles.bar}></span>

                <div className={styles.buttons}>
                  <button
                    className={`${styles.skip} mt-2`}
                    onClick={() => handleNext()}
                  >
                    {/* {strapiData?.buttons[0]?.title} */}
                    Skip
                  </button>
                  <div className={styles.btn}>
                    <button
                      className={`${styles.back} mt-2`}
                      onClick={() => handleBack()}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Back
                    </button>
                    <button
                      className={`${styles.next} mt-2`}
                      onClick={forSubmit}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <UnlockCost values={preValues} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Form2
