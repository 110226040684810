import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import { Link } from "gatsby"
import UnlockCost from "./UnlockCost"
import * as styles from "./Form3.module.scss"

import "./Form.scss"
import Stepper from "./Stepper"

const Form3 = ({ setFormValues, preValues, handleNext, handleBack, step }) => {
  const initValues = {
    description: preValues?.description || "",
  }

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const forSubmit = () => {
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    console.log(values)
    setFormValues({ ...preValues, ...values })
    handleNext()
  }

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "description":
          if (!value || value?.trim() === "")
            newErrors[key] = "This field is required!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  return (
    <div className={styles.form1}>
      <Container>
        <div className={styles.formData}>
          <Row>
            <Col lg={8}>
              <Stepper step={step} />
              <div className={styles.help}>
                <p className={styles.cost}>
                  Please describe your project briefly.
                </p>
                <Row className="">
                  <Col xs={12}>
                    <div className="IzDescribe">
                      <Form.Group controlId="message">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder={"Type description here..."}
                          name="description"
                          value={values.description || ""}
                          onChange={handleChange}
                          //   isInvalid={errors.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {/* {errors.message} */}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                {errors.description && (
                  <small type="invalid" className={"w-100 text-danger"}>
                    {errors.description}
                  </small>
                )}
                <span className={styles.bar}></span>
                <div className={styles.buttons}>
                  <div className={styles.btn}>
                    <button
                      className={`${styles.back} mt-2`}
                      onClick={() => handleBack()}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Back
                    </button>
                    <button
                      className={`${styles.next} mt-2`}
                      onClick={forSubmit}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <UnlockCost values={preValues} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Form3
