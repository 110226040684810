import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Asset from "../../images/iz-calculator/Asset.png"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"

import * as styles from "./izCalculator.module.scss"

const izCalculator = ({ handleNext }) => {
  return (
    <div className={styles.buildSoftwere}>
      <Container>
        <Col>
          <h1 className={`${styles.btn_white1_border} pb-2`}>
            {/* {strapiData?.buttons[0]?.title} */}
            Software Development Cost Calculator
          </h1>
          <div className={styles.costImg}>
            <img src={Asset} />
          </div>
          <p className={styles.heading}>
            How much does it cost to build software?
          </p>
          <p className={styles.description}>
            No software development cost estimation calculator can compete with
            the InvoZone tool. Answer the following questions and get the first
            draft of the cost estimation.
          </p>
          <button
            className={`${styles.btn_white2_border} mt-5`}
            onClick={() => handleNext()}
          >
            {/* {strapiData?.buttons[0]?.title} */}
            Lets Proceed
          </button>
        </Col>
      </Container>
    </div>
  )
}

export default izCalculator
