import { graphql } from "gatsby"
import React, { useState } from "react"
// import SEO from "../components/common/SEO"
import Calculator from "../components/iz-calculator/izCalculator"
import Form1 from "../components/iz-calculator/Form1"
import Form2 from "../components/iz-calculator/Form2"
import Form3 from "../components/iz-calculator/Form3"
import Form4 from "../components/iz-calculator/Form4"
import Form5 from "../components/iz-calculator/Form5"
import Form05 from "../components/iz-calculator/Form05"
import Form6 from "../components/iz-calculator/Form6"
import Form7 from "../components/iz-calculator/Form7"
import Form8 from "../components/iz-calculator/Form8"
import ThankYou from "../components/iz-calculator/ThankYou"
import MainLayout from "../layouts/MainLayout"
import { encodeToFormData } from "../utils"

const izCalculator = ({ data }) => {
  // const { metaTitle, metaDescription } = data?.strapiPage?.seo
  const [step, setStep] = useState(0)
  const [formValues, setFormValues] = useState({})
  const [status, setStatus] = useState(null)

  const handleNext = () => {
    // var progressBar = document.querySelectorAll("#progressbar li")
    // progressBar[step + 1]?.classList.add("active")
    setStep(prevStep => prevStep + 1)
  }

  const handleBack = () => {
    // var progressBar = document.querySelectorAll("#progressbar li")
    // progressBar[step]?.classList.remove("active")
    setStep(prevStep => prevStep - 1)
  }

  const handleReset = () => {
    setFormValues({})
    setStep(0)
  }

  const handleSubmit = async val => {
    // e.preventDefault()
    formValues["term_condition"] = val?.term_condition
    // console.log(formValues)
    const filteredTeams = Object.entries(formValues?.teams)
      .filter(([key, val]) => val?.how_much > 0 && val?.level !== "")
      .reduce((obj, [key, val]) => {
        obj[key] = val
        return obj
      }, {})
    formValues.teams = filteredTeams
    console.log("NEW DATA::", formValues)

    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/contactusforms/?id=9019`
    const formData = encodeToFormData(formValues)
    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then(res => {
        if (res.status === 429) {
          setStatus("failed")
          return
        }
        return res.json()
      })
      // console.log(result.status)
      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        setFormValues({})
        // setStatus("success")
        setStatus(null)
        handleNext()
        // setTimeout(() => {
        //   setStatus("init")
        // }, 2000)
      } else {
        // console.log(result)
        setStatus("failed")
        throw new Error("Server error!")
      }
    } catch (err) {
      setStatus("failed")
    }
  }

  // console.log("Forms Values: ", formValues)

  const Form = () => {
    // if (step === -1) {
    //   return <Calculator handleNext={handleNext} />
    // } else 
    if (step === 0) {
      return (
        <Form1
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
        />
      )
    } else if (step === 1) {
      return (
        <Form2
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
        />
      )
    } else if (step === 2) {
      return (
        <Form3
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
        />
      )
    } else if (step === 3) {
      return (
        <Form4
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
        />
      )
    } else if (step === 4) {
      return (
        <Form05
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
        />
      )
    } else if (step === 5) {
      return (
        <Form6
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
        />
      )
    } else if (step === 6) {
      return (
        <Form7
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleNext}
          step={step}
        />
      )
    } else if (step === 7) {
      return (
        <Form8
          preValues={formValues}
          setFormValues={setFormValues}
          handleBack={handleBack}
          handleNext={handleSubmit}
          step={step}
        />
      )
    } else if (step === 8) {
      return <ThankYou />
    }
  }

  return (
    <MainLayout bgChanged={false}>
      {/* <SEO title={"Software Development Cost Calculator"} /> */}
      <Form />
      {/* <Form1 /> */}
      {/* <Form2 /> */}
      {/* <Form3 /> */}
      {/* <Form4 /> */}
      {/* <Form6 /> */}
      {/* <Form7 /> */}
      {/* <Form5 /> */}
    </MainLayout>
  )
}

// export const query = graphql`
//   query iz-calculator {
//     strapiPage(slug: { eq: "contact-us" }) {
//       sections {
//         title
//         subTitle
//         cards {
//           title
//           subTitle
//           description: childStrapiComponentCardsCardDescriptionTextnode {
//             description
//           }
//           image1 {
//             alternativeText
//             localFile {
//               publicURL
//             }
//           }
//           image2 {
//             alternativeText
//             localFile {
//               publicURL
//             }
//           }
//           buttons {
//             title
//             url
//           }
//         }
//         secImages {
//           alternativeText
//           localFile {
//             childImageSharp {
//               gatsbyImageData
//             }
//             publicURL
//           }
//           url
//         }
//         buttons {
//           title
//           url
//         }
//         description: childStrapiComponentSectionsSectionDescriptionTextnode {
//           id
//           description
//         }
//       }
//       seo {
//         metaTitle
//         metaDescription
//       }
//     }
//   }
// `

export default izCalculator
