// extracted by mini-css-extract-plugin
export var active = "Form1-module--active--4156d";
export var back = "Form1-module--back--c0c20";
export var bar = "Form1-module--bar--d3536";
export var bar2 = "Form1-module--bar2--7bf80";
export var btn = "Form1-module--btn--b4127";
export var buttons = "Form1-module--buttons--77669";
export var checkmark = "Form1-module--checkmark--f8c72";
export var cost = "Form1-module--cost--4b9fc";
export var description = "Form1-module--description--274ee";
export var form1 = "Form1-module--form1--b4451";
export var formData = "Form1-module--formData--f41b0";
export var help = "Form1-module--help--3af82";
export var mainHeading = "Form1-module--mainHeading--85d2f";
export var next = "Form1-module--next--b83aa";
export var plan = "Form1-module--plan--8895e";
export var planContent = "Form1-module--planContent--1f7dd";
export var plancontent = "Form1-module--plancontent--a2cea";
export var plans = "Form1-module--plans--19882";
export var questionAns = "Form1-module--questionAns--f98e8";
export var questionMark = "Form1-module--questionMark--b997c";
export var skip = "Form1-module--skip--152a6";
export var staffCheck = "Form1-module--staffCheck--f4282";