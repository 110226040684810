import React from "react"
import "./stepper.css"

const Stepper = ({ step = 0 }) => {
  React.useEffect(() => {
    var progressBar = document.querySelectorAll("#progressbar li")
    {
      ;[...Array(7)?.keys()]?.map((val, i) =>
        progressBar[i]?.classList.remove("active")
      )
    }
    {
      ;[...Array(step + 1)?.keys()]?.map((val, i) =>
        progressBar[i]?.classList.add("active")
      )
    }
    const el_steps = document.querySelectorAll(".steps")
    {
      ;[...Array(step)?.keys()]?.map((val, i) => {
        el_steps[i]?.classList.add("right")
        el_steps[i].innerText = "✔"
        // el_steps[i].innerText = "✓"
      })
    }
  }, [])

  return (
    <div>
      <ul id="progressbar">
        {[...Array(7).keys()]?.map((val, i) => (
          <li key={val}>
            <span className="steps">{i + 1}</span>
            {/* <span className="tick"></span> */}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Stepper
