import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import mail from "../../images/iz-calculator/mail.svg"
import facebook from "../../images/iz-calculator/facebook.svg"
import twiter from "../../images/iz-calculator/twiter.svg"
import inkdin from "../../images/iz-calculator/inkdin.svg"
import insta from "../../images/iz-calculator/insta.svg"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import * as styles from "./ThankYou.module.scss"

const ThankYou = ({ nextStep }) => {
  return (
    <div className={styles.buildSoftwere}>
      <Container>
        <div className={styles.bg2}>
          <Col>
            <div className={styles.costImg}>
              <img src={mail} />
            </div>
            <p className={styles.heading}>Thank you for trusting us!</p>
            <p className={styles.description}>
              In case you need an immediate response, book a free consultation
              today.
            </p>
            <div className={`${styles.socials}`}>
              <FacebookShareButton
                // url={`www.invozone.com/events/${strapiData?.slug}`}
                // quote={strapiData.seo.metaDescription}
                // title={strapiData.seo.metaTitle}
                hashtag={[]}
              >
                <img className={styles.icons} src={facebook} borderRadius={5} />
              </FacebookShareButton>
              <LinkedinShareButton
                // url={`www.invozone.com/events/${strapiData?.slug}`}
                // quote={strapiData.seo.metaDescription}
                // title={strapiData.seo.metaTitle}
                hashtag={[]}
              >
                <img className={styles.icons} src={twiter} borderRadius={5} />
              </LinkedinShareButton>
              <TwitterShareButton
                // url={`www.invozone.com/events/${strapiData?.slug}`}
                // quote={strapiData.seo.metaDescription}
                // title={strapiData.seo.metaTitle}
                hashtag={[]}
              >
                <img className={styles.icons} src={inkdin} borderRadius={5} />
              </TwitterShareButton>
              <TwitterShareButton
                // url={`www.invozone.com/events/${strapiData?.slug}`}
                // quote={strapiData.seo.metaDescription}
                // title={strapiData.seo.metaTitle}
                hashtag={[]}
              >
                <img className={styles.icons} src={insta} borderRadius={5} />
              </TwitterShareButton>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  )
}

export default ThankYou
