import React from "react"
import { Link } from "gatsby"
import lock from "../../images/iz-calculator/lock.svg"
import clock from "../../images/iz-calculator/clock.svg"
import dots from "../../images/iz-calculator/3dots.svg"
import * as styles from "./UnlockCost.module.scss"

const UnlockCost = ({ values }) => {
  // console.log("Unlock::", values?.teams)
  return (
    <div className={styles.formData}>
      <div className={styles.unLock}>
        <p className={styles.cost}>Total Cost</p>
        <div className={styles.lockHeading}>
          <img src={lock} className={styles.lock} />
          <h3 className={styles.subHeading}>Unlock Cost</h3>
        </div>
        <p className={styles.description}>
          Complete the form and we'll schedule a call to discuss your budgetary
          requirements.
        </p>
        <div className={styles.button}>
          <Link to="/contact-us/">
            <button className={`${styles.btn_white1_border} mt-2`}>
              {/* {strapiData?.buttons[0]?.title} */}
              Book a Free Consultation
            </button>
          </Link>
        </div>
        <hr />
        <p className={styles.cost}>Estimated Time</p>
        <div className={styles.lockHeading}>
          <img src={clock} className={styles.lock} />
          <h3 className={styles.subHeading}>0 Hours</h3>
        </div>
        <div className={styles.numbers}>
          <p className={styles.cost}>Number of Resources</p>
          {values?.teams ? (
            Object.keys(
              Object.fromEntries(
                Object.entries(values?.teams)?.filter(
                  ([key, val]) => val?.how_much > 0 && val?.level !== ""
                )
              )
            )?.map((val, i) => (
              <>
                {/* <div className={styles.project}>
                  <div className={styles.number}>
                    {values?.teams[val]?.how_much}
                  </div>
                  <div className={styles.manager}>
                    {values?.teams[val]?.title}
                  </div>
                  <button className={styles.exports}>
                    {values?.teams[val]?.level?.label}
                  </button>
                  <div className={styles.dots}></div>
                </div> */}
                <div className={styles.project}>
                  <div className={styles.manager}>Project Manager</div>
                </div>
                <span className={styles.bar2}></span>
              </>
            ))
          ) : (
            <div className={styles.selected}>No Resource Selected</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnlockCost
