import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import { Link } from "gatsby"
import UnlockCost from "./UnlockCost"
import * as styles from "./Form7.module.scss"
import "./Form.scss"
import { isEmail } from "../../utils"
import Stepper from "./Stepper"

const Form7 = ({ setFormValues, preValues, handleNext, handleBack, step }) => {
  const initValues = {
    fullName: preValues?.fullName || "",
    email: preValues?.email || "",
    company: preValues?.company || "",
    role: preValues?.role || "",
  }

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const forSubmit = () => {
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    console.log(values)
    setFormValues({ ...preValues, ...values })
    handleNext()
  }

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "fullName":
          if (!value || value?.toString()?.trim() === "")
            newErrors[key] = "This field is required!"
          break
        case "email":
          if (!value || value?.toString()?.trim() === "")
            newErrors[key] = "This field is required!"
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!"
          break
        case "company":
          if (!value || value?.toString().trim() === "")
            newErrors[key] = "This field is required!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  return (
    <div className={styles.form1}>
      <Container>
        <div className={styles.formData}>
          <Row>
            <Col lg={8}>
              <Stepper step={step} />
              <div className={styles.help}>
                <p className={styles.cost}>
                  Please provide your contact details, and our team will get
                  back to you!
                </p>
                <Row className="">
                  <Col xs={12}>
                    <div className="checkForm">
                      <Form.Group controlId="name" className="mb-4">
                        <Form.Control
                          type="text"
                          placeholder={"Name*"}
                          name="fullName"
                          value={values.fullName}
                          onChange={handleChange}
                          isInvalid={errors.fullName}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute"
                        >
                          {errors.fullName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="checkForm">
                      <Form.Group controlId="email" className="mb-4">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder={"Email*"}
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={errors.email}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute"
                        >
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="checkForm">
                      <Form.Group controlId="email" className="mb-4">
                        <Form.Control
                          type="text"
                          name="company"
                          placeholder={"Company*"}
                          value={values.company}
                          onChange={handleChange}
                          isInvalid={errors.company}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute"
                        >
                          {errors.company}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="IzForm">
                      <Form.Group controlId="message">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder={"Your role in your organization"}
                          name="role"
                          value={values.role}
                          onChange={handleChange}
                          //   isInvalid={errors.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {/* {errors.message} */}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                <span className={styles.bar}></span>

                <div className={styles.buttons}>
                  <div className={styles.btn}>
                    <button
                      className={`${styles.back} mt-2`}
                      onClick={() => handleBack()}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Back
                    </button>
                    <button
                      className={`${styles.next} mt-2`}
                      onClick={forSubmit}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <UnlockCost values={preValues} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Form7
