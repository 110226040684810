// extracted by mini-css-extract-plugin
export var back = "Form7-module--back--bb257";
export var bar = "Form7-module--bar--7f271";
export var btn = "Form7-module--btn--4ef2a";
export var buttons = "Form7-module--buttons--971ea";
export var cost = "Form7-module--cost--57534";
export var form1 = "Form7-module--form1--c96d7";
export var formData = "Form7-module--formData--102be";
export var help = "Form7-module--help--72a19";
export var next = "Form7-module--next--1742d";
export var skip = "Form7-module--skip--334b9";