import React, { useContext, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import Select from "react-select"
import Form from "react-bootstrap/Form"
import Card from "react-bootstrap/Card"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { Link } from "gatsby"
import minus from "../../images/iz-calculator/cross.svg"
import plus from "../../images/iz-calculator/plus.svg"
import UnlockCost from "./UnlockCost"
import * as styles from "./Form5.module.scss"
import "./Form.scss"
import Stepper from "./Stepper"

// function changeColor(number){
//   var color = "";
//   if(number < 0 ){
//       color = "red";
//   }else if (number > 0 ){
//       color = "green";
//   }else{
//       color="white";
//   }
//   return color;
// }

// btnIncrement.addEventListener("click", function(){
//   number++;
//   counterPlaceHolder.innerHTML = number;
//   counterPlaceHolder.style.color = changeColor(number);
// });

// btnDecrement.addEventListener("click", function(){
//   number--;
//   counterPlaceHolder.innerHTML = number;
//   counterPlaceHolder.style.color = changeColor(number);
// });

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    width: "100%",
    minHeight: "43px",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#000000",
    backgroundColor: "#F8F8F8",
    border: "0px",
    borderRadius: "0.75rem",
    boxShadow: isFocused && "0 0 0 0.2rem rgb(33 118 255 / 25%)",
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#1CA0EA" : "#fff",
      "&:hover": {
        backgroundColor: isFocused && "#2179FE",
        color: "#fff",
      },
    }
  },
  menu: styles => ({
    ...styles,
    backgroundColor: "#fff",
    color: "#000",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#000", // Custom colour
  }),
}

const options = [
  { label: "Junior" },
  {
    label: "Middle",
  },
  { label: "Senior" },
]

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img src={minus} className={styles.quesColorSelected} />
          ) : (
            <img src={plus} className={styles.quesColor} />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

// const cardsData = [
//   {
//     title: `Project Manager 1`,
//     how_much: 0,
//     level: ["Expert", "Beginner", "Other"]
//   },
//   {
//     title: `Project Manager 2`,
//     how_much: 0,
//     level: ["Expert", "Beginner", "Other"]
//   },
//   {
//     title: `Project Manager 3`,
//     how_much: 0,
//     level: ["Expert", "Beginner", "Other"]
//   },
//   {
//     title: `Project Manager 4`,
//     how_much: 0,
//     level: ["Expert", "Beginner", "Other"]
//   },
//   {
//     title: `Project Manager 5`,
//     how_much: 0,
//     level: ["Expert", "Beginner", "Other"]
//   },
//   {
//     title: `Project Manager 6`,
//     how_much: 0,
//     level: ["Expert", "Beginner", "Other"]
//   },
// ]

// const cardsData = [
//   {
//     title: `Project Manager 1`,
//     how_much: 0,
//     level: [],
//   },
//   {
//     title: `Project Manager 2`,
//     how_much: 0,
//     level: [],
//   },
//   {
//     title: `Project Manager 3`,
//     how_much: 0,
//     level: [],
//   },
//   {
//     title: `Project Manager 4`,
//     how_much: 0,
//     level: [],
//   },
//   {
//     title: `Project Manager 5`,
//     how_much: 0,
//     level: [],
//   },
//   {
//     title: `Project Manager 6`,
//     how_much: 0,
//     level: [],
//   },
// ]

const cardsData_1 = {
  Project_Manager: {
    title: `Project Manager`,
    how_much: 0,
    level: "",
  },
  System_Architect: {
    title: `System Architect`,
    how_much: 0,
    level: "",
  },
  Front_end_Developer: {
    title: `Front-end Developer`,
    how_much: 0,
    level: "",
  },
  Back_end_Developer: {
    title: `Back-end Developer`,
    how_much: 0,
    level: "",
  },
  iOS_Developer: {
    title: `iOS Developer`,
    how_much: 0,
    level: "",
  },
  Android_Developer: {
    title: `Android Developer`,
    how_much: 0,
    level: "",
  },
}

const Form5 = ({ setFormValues, preValues, handleNext, handleBack, step }) => {
  const initValues = {
    teams: {
      ...cardsData_1,
      ...preValues?.teams,
      // Project_Manager_1: {
      //   title: `Project Manager 98327492`,
      //   how_much: 0,
      //   level: [],
      // },
    },
  }

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const forSubmit = () => {
    const validationErrors = validate(values)
    console.log("Errors::", validationErrors)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    console.log(values)
    setFormValues({ ...preValues, ...values })
    handleNext()
  }

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value
    setValues({ ...values })

    // if (errors.hasOwnProperty(name)) {
    //   delete errors[name]
    //   setErrors(errors)
    // }
  }

  const handleIncrement = (i, e) => {
    console.log(i)
    // const val =
    //   parseInt(document.getElementById(`counter-placeholder-${i}`).innerText) +
    //   1
    // document.getElementById(`counter-placeholder-${i}`).innerText = val
    const val = values?.teams[e]?.how_much + 1
    // console.log(val)
    setValues({
      ...values,
      teams: { ...values.teams, [e]: { ...values.teams[e], how_much: val } },
    })
    if (errors.hasOwnProperty(e)) {
      delete errors[e]
      setErrors(errors)
    }
  }

  const handleDecrement = (i, e) => {
    // console.log(i)
    // if (
    //   parseInt(document.getElementById(`counter-placeholder-${i}`).innerText) >
    //   0
    // ) {
    //   const val =
    //     parseInt(
    //       document.getElementById(`counter-placeholder-${i}`).innerText
    //     ) - 1
    //   document.getElementById(`counter-placeholder-${i}`).innerText = val
    // }
    if (values?.teams[e]?.how_much > 0) {
      const val = values?.teams[e]?.how_much - 1
      setValues({
        ...values,
        teams: { ...values.teams, [e]: { ...values.teams[e], how_much: val } },
      })
      if (errors.hasOwnProperty(e)) {
        delete errors[e]
        setErrors(errors)
      }
    }
  }

  // console.log("Length::", Object.keys(values?.teams).length / 2)
  const listLength = Object.keys(values?.teams).length / 2

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      console.log("valiudation:::", value, key)
      switch (key) {
        case "teams":
          Object.keys(value).forEach((val, i) => {
            console.log(val, i)
            if (value[val]?.how_much === 0 && value[val]?.level !== "")
              newErrors[val] = "Both field is required!"
            else if (value[val]?.how_much != 0 && value[val]?.level === "")
              newErrors[val] = "Both field is required!"
          })
          const filterVal = Object.fromEntries(
            Object.entries(value).filter(
              ([key, val]) => val?.how_much > 0 || val?.level !== ""
            )
          )
          if (Object.keys(filterVal).length > 0) {
            delete errors["All"]
            setErrors(errors)
          } else {
            newErrors["All"] = "Field is required!"
          }
          // if (!value) newErrors[key] = "This field is required!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  // console.log("values:: ", count)

  return (
    <div className={styles.form1}>
      <Container>
        <div className={styles.formData}>
          <Row>
            <Col lg={8}>
              <Stepper step={step} />
              <div className={styles.help}>
                <p className={styles.cost}>Select your team members.</p>

                <div className="form5">
                  <Accordion defaultActiveKey={`ek-0`}>
                    <Row className="gap-30">
                      <Col lg={6}>
                        {Object.keys(values?.teams)?.map(
                          (e, i) =>
                            i < Math.ceil(listLength) && (
                              <>
                                <Card key={i} border="light">
                                  <ContextAwareToggle eventKey={`ek-${i}`}>
                                    <h3
                                      className={`${styles.cardTitle} mb-0 py-2 text-left`}
                                      type="button"
                                    >
                                      {values?.teams[e]?.title}
                                    </h3>
                                  </ContextAwareToggle>
                                  <Accordion.Collapse eventKey={`ek-${i}`}>
                                    <Card.Body className={styles.textColor}>
                                      <Row>
                                        <Col lg={6}>
                                          <div className={styles.count}>
                                            <button
                                              className={styles.countBtns}
                                              id="btn-increment"
                                              onClick={() =>
                                                handleDecrement(i, e)
                                              }
                                            >
                                              -
                                            </button>
                                            <div
                                              className={styles.counterNumber}
                                              id={`counter-placeholder-${i}`}
                                            >
                                              {values?.teams[e]?.how_much}
                                            </div>
                                            <button
                                              className={styles.countBtns}
                                              id="btn-decrement"
                                              onClick={() =>
                                                handleIncrement(i, e)
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <Form.Group controlId="howDidYouHear">
                                            <Select
                                              name="howDidYouHear"
                                              value={values?.teams[e]?.level}
                                              placeholder={"Level"}
                                              onChange={val => {
                                                // if (
                                                //   errors.hasOwnProperty(
                                                //     "howDidYouHear"
                                                //   )
                                                // ) {
                                                //   delete errors["howDidYouHear"]
                                                //   setErrors(errors)
                                                // }
                                                setValues({
                                                  ...values,
                                                  teams: {
                                                    ...values.teams,
                                                    [e]: {
                                                      ...values.teams[e],
                                                      level: val || "",
                                                    },
                                                  },
                                                })
                                              }}
                                              isClearable
                                              options={options}
                                              styles={colourStyles}
                                              components={{
                                                IndicatorSeparator: () => null,
                                              }}
                                              indicatorSeparator={false}
                                            />
                                            {/* {errors.howDidYouHear && (
                                                    <small
                                                      type="invalid"
                                                      className={"text-danger"}
                                                    >
                                                      {errors.howDidYouHear}
                                                    </small>
                                                  )} */}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                                {errors[e] && (
                                  <small
                                    type="invalid"
                                    className={"w-100 text-danger"}
                                  >
                                    {errors[e]}
                                  </small>
                                )}
                              </>
                            )
                        )}
                      </Col>
                      <Col lg={6}>
                        {Object.keys(values?.teams)?.map(
                          (e, i) =>
                            i > Math.floor(listLength) && (
                              <>
                                <Card key={i} border="light">
                                  <ContextAwareToggle eventKey={`ek-${i}`}>
                                    <h3
                                      className={`${styles.cardTitle} mb-0 py-2 text-left`}
                                      type="button"
                                    >
                                      {values?.teams[e]?.title}
                                    </h3>
                                  </ContextAwareToggle>
                                  <Accordion.Collapse eventKey={`ek-${i}`}>
                                    <Card.Body className={styles.textColor}>
                                      <Row>
                                        <Col lg={6}>
                                          <div className={styles.count}>
                                            <button
                                              className={styles.countBtns}
                                              id="btn-increment"
                                              onClick={() =>
                                                handleDecrement(i, e)
                                              }
                                            >
                                              -
                                            </button>
                                            <div
                                              className={styles.counterNumber}
                                              id={`counter-placeholder-${i}`}
                                            >
                                              {values?.teams[e]?.how_much}
                                            </div>
                                            <button
                                              className={styles.countBtns}
                                              id="btn-decrement"
                                              onClick={() =>
                                                handleIncrement(i, e)
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <Form.Group controlId="howDidYouHear">
                                            <Select
                                              name="howDidYouHear"
                                              value={values?.teams[e]?.level}
                                              placeholder={"Level"}
                                              onChange={val => {
                                                // if (
                                                //   errors.hasOwnProperty(
                                                //     "howDidYouHear"
                                                //   )
                                                // ) {
                                                //   delete errors["howDidYouHear"]
                                                //   setErrors(errors)
                                                // }
                                                setValues({
                                                  ...values,
                                                  teams: {
                                                    ...values.teams,
                                                    [e]: {
                                                      ...values.teams[e],
                                                      level: val || "",
                                                    },
                                                  },
                                                })
                                              }}
                                              isClearable
                                              options={options}
                                              styles={colourStyles}
                                              components={{
                                                IndicatorSeparator: () => null,
                                              }}
                                              indicatorSeparator={false}
                                            />
                                            {/* {errors.howDidYouHear && (
                                                    <small
                                                      type="invalid"
                                                      className={"text-danger"}
                                                    >
                                                      {errors.howDidYouHear}
                                                    </small>
                                                  )} */}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                                {errors[e] && (
                                  <small
                                    type="invalid"
                                    className={"w-100 text-danger"}
                                  >
                                    {errors[e]}
                                  </small>
                                )}
                              </>
                            )
                        )}
                      </Col>
                      {errors.All && (
                        <small type="invalid" className={"w-100 text-danger"}>
                          {errors.All}
                        </small>
                      )}
                    </Row>
                  </Accordion>
                </div>
                <span className={styles.bar}></span>

                <div className={styles.buttons}>
                  <button
                    className={`${styles.skip} mt-2`}
                    onClick={() => handleNext()}
                  >
                    {/* {strapiData?.buttons[0]?.title} */}
                    Skip
                  </button>
                  <div className={styles.btn}>
                    <button
                      className={`${styles.back} mt-2`}
                      onClick={() => handleBack()}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Back
                    </button>
                    <button
                      className={`${styles.next} mt-2`}
                      onClick={forSubmit}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <UnlockCost values={preValues} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Form5
