// extracted by mini-css-extract-plugin
export var back = "Form05-module--back--44adb";
export var bar = "Form05-module--bar--52ae7";
export var btn = "Form05-module--btn--ce7f0";
export var buttons = "Form05-module--buttons--243fd";
export var cost = "Form05-module--cost--809fc";
export var form1 = "Form05-module--form1--5beeb";
export var formData = "Form05-module--formData--84d9d";
export var help = "Form05-module--help--984f4";
export var input = "Form05-module--input--a1b05";
export var next = "Form05-module--next--16225";
export var planContent = "Form05-module--planContent--10d9e";
export var planDetails = "Form05-module--planDetails--92d17";
export var skip = "Form05-module--skip--6a269";
export var staffCheck = "Form05-module--staffCheck--ed46a";