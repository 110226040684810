// extracted by mini-css-extract-plugin
export var back = "Form8-module--back--d00a5";
export var bar = "Form8-module--bar--2f605";
export var btn = "Form8-module--btn--a0149";
export var buttons = "Form8-module--buttons--06bdb";
export var checkmark = "Form8-module--checkmark--2f9f9";
export var cost = "Form8-module--cost--01dae";
export var form1 = "Form8-module--form1--0c9f5";
export var formData = "Form8-module--formData--fc7ca";
export var help = "Form8-module--help--34a1c";
export var next = "Form8-module--next--bc532";
export var planContent = "Form8-module--planContent--1fd7f";
export var planDetails = "Form8-module--planDetails--3b672";
export var privacy = "Form8-module--privacy--00616";
export var questionAns = "Form8-module--questionAns--8d3a0";
export var questionMark = "Form8-module--questionMark--944bf";
export var skip = "Form8-module--skip--67bd2";
export var staffCheck = "Form8-module--staffCheck--dc1c7";