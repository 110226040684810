// extracted by mini-css-extract-plugin
export var back = "Form4-module--back--edc51";
export var bar = "Form4-module--bar--0b3fb";
export var btn = "Form4-module--btn--d2c6b";
export var buttons = "Form4-module--buttons--b73bf";
export var checkmark = "Form4-module--checkmark--cab0d";
export var cost = "Form4-module--cost--47e0c";
export var form1 = "Form4-module--form1--16d26";
export var formData = "Form4-module--formData--e953d";
export var help = "Form4-module--help--7fb11";
export var next = "Form4-module--next--e52a5";
export var planContent = "Form4-module--planContent--7d8e2";
export var planDetails = "Form4-module--planDetails--67f2c";
export var questionAns = "Form4-module--questionAns--23c21";
export var questionMark = "Form4-module--questionMark--51c35";
export var skip = "Form4-module--skip--2f769";
export var staffCheck = "Form4-module--staffCheck--a0cc2";