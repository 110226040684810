import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
import UnlockCost from "./UnlockCost"
import * as styles from "./Form05.module.scss"
import Stepper from "./Stepper"

const cardsData = [
  {
    description: `Project Manager`,
  },
  {
    description: `System Architect`,
  },
  {
    description: `Front-end Developer`,
  },
  {
    description: `Back-end Developer`,
  },
  {
    description: `iOS Developer`,
  },
  {
    description: `Android Developer`,
  },
  {
    description: `QA Engineer`,
  },
  {
    description: `DevOps Engineer`,
  },
  {
    description: `UI/UX Designers`,
  },
  {
    description: `Data Scientists`,
  },
  {
    description: `Business Analyst`,
  },
  {
    description: `Cybersecurity Engineer`,
  },
]

const Form4 = ({ setFormValues, preValues, handleNext, handleBack, step }) => {
  const initValues = {
    collaborate: preValues?.collaborate || "",
  }

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const forSubmit = () => {
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    console.log(values)
    setFormValues({ ...preValues, ...values })
    handleNext()
  }

  // const forSubmit = () => {
  //   console.log(values)
  //   setFormValues({ ...preValues, ...values })
  //   handleNext()
  // }

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = [...values.collaborate, value]
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "collaborate":
          if (!value) newErrors[key] = "This field is required!"
          break
        default:
          break
      }
    }
    return newErrors
  }

  return (
    <div className={styles.form1}>
      <Container>
        <div className={styles.formData}>
          <Row>
            <Col lg={8}>
              <Stepper step={step} />
              <div className={styles.help}>
                <p className={styles.cost}>How can we collaborate with you?</p>
                <Row className="">
                  {cardsData?.map((item, index) => (
                    <Col lg={6}>
                      <div className={styles.staffCheck}>
                        <label>
                          <input
                            className={styles.input}
                            type="checkbox"
                            name={"collaborate"}
                            value={item?.description}
                            checked={values?.collaborate.includes(
                              item?.description
                            )}
                            onChange={handleChange}
                          />
                          {item?.label}
                          <span className={styles.checkmark} />
                          <div className={styles.planContent}>
                            <p className={styles.planDetails}>
                              {item?.description}
                            </p>
                          </div>
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>
                {errors.collaborate && (
                  <small type="invalid" className={"w-100 text-danger"}>
                    {errors.collaborate}
                  </small>
                )}
                <span className={styles.bar}></span>

                <div className={styles.buttons}>
                  <div className={styles.btn}>
                    <button
                      className={`${styles.back} mt-2`}
                      onClick={() => handleBack()}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Back
                    </button>
                    <button
                      className={`${styles.next} mt-2`}
                      onClick={forSubmit}
                    >
                      {/* {strapiData?.buttons[0]?.title} */}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <UnlockCost values={preValues} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Form4
